import './App.css';

function App() {
  return (
    <div className="app">
     <iframe src="https://flowcv.me/idimmanuel" name="frame" scrolling={"auto"} width="100%" height="100%" />
    </div>
  );
}

export default App;
